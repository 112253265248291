import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { PlansService } from 'src/app/services/stripe/plans.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit {

  interval?: string;
  products: any[];
  breakpoint: number;
  constructor(private plansService: PlansService, private alertService: AlertService) { }

  public onToggle(event: MatSlideToggleChange) {

    if (event.checked) {
      this.interval = 'Yearly';
      this.plansService.getPlansByYear().subscribe(
        results => this.onSuccess(results),
        errors => this.handleError(errors),
        () => this.onComplete()
      );
    } else {
      this.interval = 'Monthly';
      this.plansService.getPlansByMonth().subscribe(
        results => this.onSuccess(results),
        errors => this.handleError(errors),
        () => this.onComplete()
      );
    }

  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

  ngOnInit() {
    this.interval = 'Monthly';
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    this.plansService.getPlansByMonth().subscribe(
      results => this.onSuccess(results),
      errors => this.handleError(errors),
      () => this.onComplete()
    );
  }

  onSuccess(data: any): void {
    this.products = data;
  }

  handleError(errors): void {

    this.alertService.errors(errors);

  }


  onComplete(): void {

  }
}
