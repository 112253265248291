import { Injectable } from '@angular/core';
import { EditUser } from 'src/app/models/edit-user.model';
import { UserDeleteModel } from 'src/app/models/user-delete.model';
import { UserModel } from 'src/app/models/user-model.model';
import { IUser } from 'src/app/models/user.model';
import { UserEndpointService } from './user-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private userEndPoint: UserEndpointService) { }

  getUsers() {
    return this.userEndPoint.getUsersEndpoint<IUser[]>();
  }

  addUser(user: any) {
    return this.userEndPoint.addUserEndPoint<UserModel>(user);
  }
  deleteUser(user: any) {
    return this.userEndPoint.deleteUserEndpoint<UserDeleteModel>(user);
  }
  getUser(email: string) {
    return this.userEndPoint.getUserEndpoint<IUser>(email);
  }
  editUser(user: EditUser) {
    return this.userEndPoint.editUserEndPoint<EditUser>(user);
  }
}
