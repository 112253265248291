import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {InviteService} from 'src/app/services/invite/invite.service';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  constructor(private fb: FormBuilder, private inviteService: InviteService, private alertService: AlertService,
              public translator: TranslatorService
  ) {
  }

  get emails(): FormArray {
    return this.form.get('emails') as FormArray;
  }

  expArray = {};
  emailArray: [];
  isSendingEmail?: boolean;

  form = new FormGroup({
    emails: new FormArray([new FormControl('')]),
  });

  ngOnInit() {
    this.emailArray = this.emails.value.filter(e => e !== '' && e != null);
  }

  add() {
    this.emails.push(new FormControl());
  }

  onSubmit() {
    this.isSendingEmail = true;
    this.emailArray = this.emails.value.filter(e => e !== '' && e != null);

    if (this.emailArray.length > 0) {
      this.inviteService.send(this.form.value).subscribe(
        results => this.onSuccess(results),
        errors => this.handleError(errors),
        () => this.onComplete()
      );
    } else {
      this.alertService.error(this.translator.t('Invites'), this.translator.t('Please enter email'));
      this.isSendingEmail = false;
    }

  }

  onSuccess(data: any): void {
    this.isSendingEmail = false;
    this.alertService.success(data.key, data.message);
  }

  handleError(errors): void {
    this.alertService.errors(errors);
  }

  onComplete(): void {
  }

}
