import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import {TranslatorService} from '../../services/translator/translator.service';
@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {

  emailAddress?: string;
  confirmed?: string;
  isConfirmingAccount?: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private alertService: AlertService,
    public translator: TranslatorService
    ) {
    const email: string = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.emailAddress = email;
  }

  ngOnInit(): void {

    this.isConfirmingAccount = true;
    this.accountService.getConfirmAccount(this.emailAddress).subscribe(
      results => this.Success(results),
      errors => this.Errors(errors),
      () => this.Complete()
    );
  }
  Complete() {
  }
  Errors(errors: any) {
    this.isConfirmingAccount = false;
    this.alertService.errors(errors);
  }
  Success(results: any) {
    this.isConfirmingAccount = false;
    this.router.navigateByUrl('/login');
    this.alertService.success('Confirmed', this.translator.t('Account confirmed successfully'));
    this.confirmed = results?.confirmed;
  }

}
