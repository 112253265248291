import { Injectable } from '@angular/core';
import { RingsizeEndpoint } from './ringsize-endpoint';

@Injectable({
  providedIn: 'root'
})
export class RingsizeService {

  constructor(private ringSizeEndpoint: RingsizeEndpoint) { }

  getRingSizes() {
    return this.ringSizeEndpoint.getRingSizesEndpoint();
  }

  getRingSizesCountsByFinger() {
    return this.ringSizeEndpoint.getCountOfRingSizeByFingerEndPoint();
  }

  getCountOfRingSizeLettersForIndexFinger(){
    return this.ringSizeEndpoint.getCountOfRingSizeLettersForIndexFingerEndPoint();
  }

  getCountOfRingSizeLettersForLitteFinger(){
    return this.ringSizeEndpoint.getCountOfRingSizeLettersForLittleFingerEndPoint();
  }

  getCountOfRingSizeLettersForMiddleFinger(){
    return this.ringSizeEndpoint.getCountOfRingSizeLettersForMiddleFingerEndPoint();
  }

  getCountOfRingSizeLettersForRingFinger(){
    return this.ringSizeEndpoint.getCountOfRingSizeLettersForRingFingerEndPoint();
  }
}
