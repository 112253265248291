import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditUser } from 'src/app/models/edit-user.model';
import { UserModel } from 'src/app/models/user-model.model';
import { EqualValidator } from '../../validators/equal.validator';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { IFormData } from '../../../models/form-data';
import { createFormGroup } from '../../../shared/form-control/form-control.utility';
import { FormFieldType } from '../../../models/form-field-type.enum';
import {TranslatorService} from '../../../services/translator/translator.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  update?: EditUser;
  user: UserModel;
  claims: any[];
  selectedClaims: any[];
  editUserForm: FormGroup;
  isSubmitted = false;
  emailAddress: string;
  loading = false;

  // Dynamic form controls
  controls: IFormData<{ id?: number; claimValue?: string; }>[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    public translator: TranslatorService
    ) {
    const email: string = activatedRoute.snapshot.params.email;
    this.emailAddress = email;
  }

  onDataLoadFailed(errors: any): void {
    this.loading = false;
  }

  onDataLoadSuccessful(results: any): void {
    this.user = results.user;
    this.claims = results.allClaims;
    this.selectedClaims = results.claims;
    this.loading = false;
    this.buildForm();
  }


  ngOnInit(): void {
    this.loading = true;
    this.userService.getUser(this.emailAddress).subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error)
    );
  }

  get formControls() {
    return this.editUserForm.controls;
  }

  get password() {
    return this.editUserForm.get('password');
  }

  get newPassword() {
    return this.password.get('newPassword');
  }

  get confirmPassword() {
    return this.password.get('confirmPassword');
  }

  editUser() {
    this.update = this.editUserForm.value;
    this.update.oldEmail = this.emailAddress;
    this.update.claims = this.update.claims.map(c => c.claimValue);
    this.update.isEnabled = this.user?.isEnabled || false;


    this.userService.editUser(this.update).subscribe(
      data => this.onSuccess(data),
      error => this.handleError(error),
      () => this.onComplete());
  }

  onSuccess(data: any): void {
    this.alertService.success(data.key, data.message);
  }


  handleError(errors): void {
    this.alertService.errors(errors);
  }

  private buildForm() {// Initialize form controls
    this.controls = [
      {
        type: FormFieldType.Email,
        label: 'Email',
        name: 'emailAddress',
        value: this.user.email,
        validators: [Validators.required, Validators.email]
      },
      { type: FormFieldType.Text, label: 'First Name', name: 'firstname', value: this.user.firstname, validators: [Validators.required] },
      { type: FormFieldType.Text, label: 'Surname', name: 'surname', value: this.user.surname, validators: [Validators.required] },
      {
        type: FormFieldType.Group,
        name: 'password',
        controls: [
          {
            type: FormFieldType.Password,
            label: 'New Password',
            name: 'newPassword',
            validators: [Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/)]
          },
          {
            type: FormFieldType.Password,
            label: 'Confirm Password',
            name: 'confirmPassword',
            validators: [EqualValidator('newPassword')]
          },
        ]
      },
      {
        type: FormFieldType.MultiSelect,
        label: 'Roles',
        name: 'claims',
        displayName: 'claimValue',
        value: this.selectedClaims.map(c => {
          return { claimValue: c };
        }),
        options: this.claims.map(c => {
          return { claimValue: c };
        })
      },
      { type: FormFieldType.Checkbox, label: 'Is Enabled', name: 'isEnabled', value: (this.user?.isEnabled) || false },
    ];
    this.editUserForm = createFormGroup(this.controls);
  }

  onComplete(): void {
  }

}
