import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ClaimsService} from 'src/app/services/claims/claims.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../shared/dialog/confirm-dialog.component';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {TableColumn} from 'src/app/shared/table/TableColumn';
import {TranslatorService} from '../../../services/translator/translator.service';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class RoleListComponent implements AfterViewInit, OnInit {
  @Output() newMessageEvent = new EventEmitter<boolean>();
  roleTableData = [];
  roleTableColumns: TableColumn[] = [
    {
      name: 'Name',
      key: 'claimValue',
      dataType: 'string',
      isButton: false,
      isOrderStatus: false,
      isSortable: true,
      isVisibleMobile: true,
      routerLink: null,
      icon: null
      ,
      toolTipText: ''
    },
    {
      name: 'Edit',
      key: 'edit',
      dataType: '',
      isButton: false,
      isOrderStatus: false,
      isSortable: false,
      isVisibleMobile: true,
      routerLink: {path: '/roles/edit-role', param: 'claimValue', icon: 'edit'},
      icon: null
      ,
      toolTipText: this.translator.t('Click here to edit role')
    },
    {
      name: 'Delete',
      key: 'delete',
      dataType: 'default',
      isButton: true,
      isOrderStatus: false,
      isSortable: false,
      isVisibleMobile: true,
      routerLink: null,
      icon: 'delete',
      toolTipText: this.translator.t('Click here to delete role')
    }
  ];

  /**
   *
   */
  constructor(
    public location: Location,
    public router: Router,
    private claimService: ClaimsService,
    public dialog: MatDialog,
    private alertService: AlertService,
    public translator: TranslatorService
  ) {


  }

  ngAfterViewInit(): void {

    this.claimService.getRoles().subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error)
    );

  }


  ngOnInit() {


  }

  deleteRole(claimValue) {

    this.dialog.open(ConfirmDialogComponent, {
      data: {title: this.translator.t('Delete Role'), message: this.translator.t('Do you want to delete the role?'), yes:  this.translator.t('Yes'), no:  this.translator.t('No')}
    }).afterClosed().subscribe(result => {
      if (result ===  this.translator.t('Yes')) {
        this.claimService.deleteRole(claimValue).subscribe(
          data => this.onSuccessfulDelete(data, claimValue),
          error => this.onSuccessfulDeleteErrors(error)
        );
      } else {
        this.dialog.closeAll();
      }
    });


  }

  onSuccessfulDelete(data, role: any): void {
    this.alertService.success(data.key, data.message);
    this.roleTableData = this.roleTableData.filter(item => item !== role);
  }


  onSuccessfulDeleteErrors(errors: any): void {
    this.alertService.errors(errors);
  }

  onDataLoadFailed(errors: any): void {
    this.alertService.errors(errors);
  }

  onDataLoadSuccessful(results: any): void {
    this.roleTableData = results;

  }

}
