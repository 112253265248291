import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IRole} from 'src/app/models/role.model';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {AuthService} from 'src/app/services/auth/auth.service';
import {ClaimsService} from 'src/app/services/claims/claims.service';
import {IFormData} from '../../../models/form-data';
import {createFormGroup} from '../../../shared/form-control/form-control.utility';
import {FormFieldType} from '../../../models/form-field-type.enum';
import {TranslatorService} from '../../../services/translator/translator.service';


@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  addRoleForm: FormGroup;
  roleName?: string;
  role?: IRole;

  // Dynamic form controls
  controls: IFormData<any>[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private claimService: ClaimsService,
    private alertService: AlertService,
    public translator: TranslatorService
  ) {
    this.buildForm();
  }

  onDataLoadFailed(errors: any): void {

  }

  onDataLoadSuccessful(results: any): void {

    this.role = results;

  }

  ngOnInit(): void {
  }

  get formControls() {
    return this.addRoleForm.controls;
  }

  addRole() {
    const role = this.addRoleForm.value;
    const claimValue = role.roleName;
    this.claimService.addRole(claimValue).subscribe(
      data => this.onSuccess(data),
      error => this.handleError(error),
      () => this.onComplete());
  }

  onSuccess(data): void {
    this.alertService.success(data.key, data.message);
  }

  handleError(errors): void {
    this.alertService.errors(errors);
  }

  private buildForm() {
    // Initialize form controls
    this.controls = [
      {type: FormFieldType.Text, label: 'Role', name: 'roleName', validators: [Validators.required]},
    ];
    this.addRoleForm = createFormGroup(this.controls);
  }

  onComplete(): void {

  }

}
