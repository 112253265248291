import {
  Component,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from './services/auth/auth.service';

import { ClaimsService } from './services/claims/claims.service';
import { StripeService } from './services/stripe/stripe.service';
import { Stripe } from './models/stripe.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog.component';
import { AlertService } from './services/alert-service/alert-service.service';

import { Platform, AlertController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ProfileService } from './services/profile/profile.service';
import {TranslatorService} from './services/translator/translator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

  options = {
    autoClose: false,
    keepAfterRouteChange: false,
  };

  hasRouteData?: boolean;
  public roles: any;
  price?: any;
  stripe?: any;
  start?: any;
  end?: any;
  currentUserRoles: any;
  days?: any;
  isSubscriptionActiveOrTrialling: any;
  status: any;
  isLoggedIn: boolean;
  isMobile: boolean;
  private mobileQueryListener: () => void;
  isCollapsed: boolean;
  isClosed: boolean;
  newNotificationCount = 0;
  appTitle = 'Boilerplate';
  avatar?: string;

  mobileQuery: MediaQueryList;
  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;

  constructor(
    private claimService: ClaimsService,
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public auth: AuthService,
    private deviceService: DeviceDetectorService,
    private stripeService: StripeService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private profileService: ProfileService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private alertCtrl: AlertController,
    public translator: TranslatorService
  ) {
    this.isUserLoggedIn();
    this.mobileQuery = media.matchMedia('(max-width: 100px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (this.isMobile || isTablet) {
      this.isClosed = true;
    }

    this.userRoles();
    this.getAvatar();
    this.initializeApp();
  }

  getAvatar() {
    if (this.isUserLoggedIn()) {
      this.profileService.getAvatar().subscribe(
        (avatar) => this.onAvatarSuccess(avatar),
        (errors) => this.handleAvatarError(errors),
        () => this.onAvatarComplete()
      );
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      window['plugins'] && window['plugins'].OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });

      let notificationOpenedCallback = function (jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      };

      let iosSettings = {};
      iosSettings['kOSSettingsKeyAutoPrompt'] = false;
      iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

      window['plugins'] && window['plugins'].OneSignal.startInit(
        'a960222d-1823-49f6-9a99-a6dad3b551a8',
        '935807589936'
      )
        .handleNotificationOpened(notificationOpenedCallback)
        .iOSSettings(iosSettings)
        .inFocusDisplaying(
          window['plugins'] && window['plugins'].OneSignal.OSInFocusDisplayOption.Notification
        )
        .endInit();

      window['plugins'] && window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(
        function (accepted) {
          console.log('User accepted notifications: ' + accepted);
        }
      );
    });
  }

  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          },
        },
      ],
    });
    alert.present();
  }

  Success() {}

  ngAfterViewChecked(): void {}

  openDialog() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title:  this.translator.t('Logout'),
          message: this.translator.t('Do you want to logout?'),
          yes:  this.translator.t('Yes'),
          no:  this.translator.t('No'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result ===  this.translator.t('Yes')) {
          this.logout();
        } else {
          this.dialog.closeAll();
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.auth.getRoles() != null) {
      if (!this.auth.getRoles().includes('admin')) {
        this.subscriptionStatus();
      }
    }

    this.userRoles();
    this.getAvatar();
  }

  ngOnInit(): void {
    this.subscriptionStatus();
    this.userRoles();
    this.isCollapsed = true;
    if (this.status != null) {
      this.stripeService.getPrice().subscribe(
        (results) => this.onPriceLoadSuccessful(results),
        (error) => this.onPriceLoadFailed(error),
        () => this.onPriceComplete()
      );
    }

    if (this.status != null) {
      if (this.auth.getRoles() != null) {
        if (!this.auth.getRoles().includes('admin')) {
          this.stripeService.getPrice().subscribe(
            (results) => this.onPriceLoadSuccessful(results),
            (error) => this.onPriceLoadFailed(error),
            () => this.onPriceComplete()
          );
        }
      }
    }

    if (this.status != null) {
      if (this.auth.getRoles() != null) {
        if (!this.auth.getRoles().includes('admin')) {
          this.stripeData();
        }
      }
    }
  }

  public stripeData() {
    if (this.auth.getRoles() != null) {
      if (!this.auth.getRoles().includes('admin')) {
        this.stripeService.getStripeProfile().subscribe(
          (results) => this.onStripeProfileLoad(results),
          (error) => this.onStripeProfileFailed(error),
          () => this.onStripeProfileComplete()
        );

        this.subscriptionStatus();
      }
    }
  }

  onStripeProfileComplete(): void {}

  onStripeProfileFailed(error: any): void {}

  onStripeProfileLoad(results: Stripe): void {
    this.stripe = results;
    this.subscriptionStatus();
  }

  onPriceComplete() {}

  onPriceLoadFailed(error: any) {}

  onPriceLoadSuccessful(results: any) {
    this.price = results;
  }

  ngOnDestroy() {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  close() {
    this.isClosed = !this.isClosed;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['login']);
    this.auth.removeToken();
    this.auth.removeRoles();
    this.auth.removeSubscriptionStatus();
  }

  isUserLoggedIn() {
    this.isLoggedIn = this.auth.isLoggedIn();
    return this.isLoggedIn;
  }

  userRoles() {
    if (this.auth.getRoles() == null) {
      if (this.auth.isLoggedIn()) {
        return this.claimService.getUserRoles().subscribe(
          (userRoles) => {
            return this.onSuccess(userRoles);
          },
          (errors) => this.handleError(errors),
          () => this.onComplete()
        );
      }
    } else {
      this.currentUserRoles = this.auth.getRoles();
    }
  }

  subscriptionStatus() {
    if (this.auth.getRoles() != null) {
      if (!this.auth.getRoles().includes('admin')) {
        if (this.auth.isLoggedIn()) {
          return this.stripeService.getSubscriptionStatus().subscribe(
            (status) => {
              return this.onStripeStatusSuccess(status);
            },
            (errors) => this.handleStripeStatusError(errors),
            () => this.onStripeStatusComplete()
          );
        }
      }
    }
  }

  onStripeStatusComplete() {}

  handleStripeStatusError(errors: any) {}

  onStripeStatusSuccess(data: any) {
    if (data != null) {
      this.status = data.status;
      this.isSubscriptionActiveOrTrialling = data.isActiveOrTrial;

      this.alertService.subscription(
        'Subscription',
        this.translator.t('Trial mode'),
        this.status,
        this.isSubscriptionActiveOrTrialling,
        this.options
      );
      sessionStorage.setItem('STATUS', JSON.stringify(status));
    }
  }

  onComplete() {}

  handleError(errors: any) {
    console.log(errors);
  }

  onAvatarComplete() {}

  handleAvatarError(errors: any) {}

  onSuccess(userRoles: any) {
    this.currentUserRoles = userRoles;
    if (this.auth.getRoles() == null) {
      sessionStorage.setItem('ROLES', String(userRoles));
    } else {
      this.currentUserRoles = this.auth.getRoles();
    }
  }

  onAvatarSuccess(result: any) {
    this.avatar = result.avatar;
  }
}
