import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimsEndpointService {
  public baseUrl = environment.api;

  constructor(private http: HttpClient) { }

  get getAllClaimsUrl() { return this.baseUrl + 'claim/allclaims'; }
  get getClaimUrl() { return this.baseUrl + 'claim/getclaim'; }
  get addClaimUrl() { return this.baseUrl + 'claim/add'; }
  get editRoleUrl() { return this.baseUrl + 'claim/edit'; }
  get deleteRoleUrl() { return this.baseUrl + 'claim/delete'; }
  get getUserRolesUrl() { return this.baseUrl + 'claim/userClaims'; }

  getUserRoles<T>(): Observable<T> {

    return this.http.post<T>(this.getUserRolesUrl, null).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }


  deleteRoleEndpoint<T>(claimValue: any): Observable<T> {

    return this.http.post<T>(this.deleteRoleUrl, JSON.stringify(claimValue)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }
  editRoleEndPoint<T>(claim: any) {
    return this.http.post<T>(this.editRoleUrl, JSON.stringify(claim)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }
  addRoleEndpoint<T>(claimValue: any) {
    return this.http.post<T>(this.addClaimUrl, JSON.stringify(claimValue)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }
  getRoleEndpoint<T>(claimValue) {
    const endpointUrl = `${this.getClaimUrl}?claimValue=${claimValue}`;
    return this.http.get<T>(endpointUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getClaimsEndPoint() {

    return this.http.get(this.getAllClaimsUrl).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }


  getRolesEndpoint<T>(): Observable<T> {

    return this.http.get<T>(this.getAllClaimsUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  protected handleError(message) {
    return throwError(message.error);
  }
}
