import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IPlan } from 'src/app/models/plan';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlansEndpointService {

  public baseUrl = environment.api;

  constructor(private http: HttpClient) { }

  get getPlansMonthUrl() { return this.baseUrl + 'price/getproductsbymonth'; }
  get getPlansYearUrl() { return this.baseUrl + 'price/getproductsbyyear'; }

  getPlansByMonth(): Observable<IPlan> {
    return this.http.get<IPlan>(this.getPlansMonthUrl).pipe<any>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  getPlansByYear(): Observable<IPlan> {
    return this.http.get<IPlan>(this.getPlansYearUrl).pipe<any>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  protected handleError(message) {
    return throwError(message.error);
  }


}
