import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { MatCardModule } from '@angular/material/card';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FooterModule } from './shared/footer/footer.component';
import { PageHeaderComponent } from './shared/page-header/page-header.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { PageSettingsToolbarComponent } from './shared/page-settings-toolbar/page-settings-toolbar.component';
import { UsersListComponent } from './components/admin/users-list/users-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EditUserComponent } from './components/admin/edit-user/edit-user.component';
import { MatSelectModule } from '@angular/material/select';
import { AddUserComponent } from './components/admin/add-user/add-user.component';
import { RoleListComponent } from './components/admin/role-list/role-list.component';
import { AddRoleComponent } from './components/admin/add-role/add-role.component';
import { EditRoleComponent } from './components/admin/edit-role/edit-role.component';
import { ProfileComponent } from './components/profile/profile.component';
import { StripeUpgradeComponent } from './components/stripe/stripe-upgrade/stripe-upgrade.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ChartsModule } from 'ng2-charts';
import { ProductSalesChartComponent } from './components/charts/product-sales-chart/product-sales-chart.component';
import { SalesTrafficChartComponent } from './components/charts/sales-traffic-chart/sales-traffic-chart.component';
import { AnnualSalesChartComponent } from './components/charts/annual-sales-chart/annual-sales-chart.component';
import { MiniCardComponent } from './shared/mini-card/mini-card.component';
import { CardComponent } from './shared/card/card.component';
import { StoreSessionsChartComponent } from './components/charts/store-sessions-chart/store-sessions-chart.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog.component';
import { AlertComponent } from './shared/alert/alert/alert.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AboutComponent } from './components/about/about.component';
import { RouteReuseStrategy } from '@angular/router';
import { InviteComponent } from './components/invite/invite.component';
import { PlansComponent } from './components/stripe/plans/plans.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormControlModule } from './shared/form-control/form-control.module';
import { NgxElectronModule } from 'ngx-electron';
import { ChartComponent } from './shared/chart/chart.component';
import { TableComponent } from './shared/table/table.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { FingersComponent } from './components/charts/ringsize/fingers/fingers.component';
import { LettersByIndexComponent } from './components/charts/counts/letters-by-index/letters-by-index.component';
import { LettersByComponent } from './components/charts/counts/letters-by-little/letters-by-little.component';
import { LettersByMiddleComponent } from './components/charts/counts/letters-by-middle/letters-by-middle.component';
import { LettersByRingComponent } from './components/charts/counts/letters-by-ring/letters-by-ring.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SubscribeComponent,
    DashboardComponent,
    PageHeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AboutComponent,
    ConfirmAccountComponent,
    PageSettingsToolbarComponent,
    UsersListComponent,
    EditUserComponent,
    AddUserComponent,
    RoleListComponent,
    AddRoleComponent,
    EditRoleComponent,
    ProfileComponent,
    StripeUpgradeComponent,
    ProductSalesChartComponent,
    SalesTrafficChartComponent,
    AnnualSalesChartComponent,
    MiniCardComponent,
    CardComponent,
    StoreSessionsChartComponent,
    ConfirmDialogComponent,
    AlertComponent,
    InviteComponent,
    PlansComponent,
    ChartComponent,
    TableComponent,
    FingersComponent,
    LettersByIndexComponent,
    LettersByComponent,
    LettersByMiddleComponent,
    LettersByRingComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    MatExpansionModule,
    MatMenuModule,
    FlexLayoutModule,
    MatCardModule,
    LayoutModule,
    FooterModule,
    DragDropModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatDialogModule,
    ChartsModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormControlModule,
    NgxElectronModule,
    MatTooltipModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    OneSignal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
