import {Component} from '@angular/core';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  constructor(
    public translator: TranslatorService
  ) {

  }

}
