import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeEndpointService {

  public baseUrl = environment.api;

  constructor(private http: HttpClient) { }


  get getStripeProfileUrl() { return this.baseUrl + 'stripe/getStripeProfile'; }
  get upgradeStripeSubscriptionUrl() { return this.baseUrl + 'stripe/upgrade'; }
  get cancelStripeSubscriptionUrl() { return this.baseUrl + 'stripe/cancel'; }
  get restartStripeSubscriptionUrl() { return this.baseUrl + 'stripe/restart'; }
  get getPriceUrl() { return this.baseUrl + 'stripe/getprice'; }
  get getStatusUrl() { return this.baseUrl + 'stripe/getstatus'; }

  getSubscriptionStatus(): Observable<boolean> {
    return this.http.get<boolean>(this.getStatusUrl).pipe<boolean>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  restartStripeSubscription() {
    return this.http.post(this.restartStripeSubscriptionUrl, null).pipe(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  cancelStripeSubscription() {
    return this.http.post(this.cancelStripeSubscriptionUrl, null).pipe(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  upgradeStripeSubscriptionEndpoint<T>(cardDetails: any) {
    return this.http.post<T>(this.upgradeStripeSubscriptionUrl, JSON.stringify(cardDetails)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  getStripeProfileEndpoint<T>() {
    return this.http.get<T>(this.getStripeProfileUrl).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  protected handleError(message) {
    return throwError(message.error);
  }


  getPriceEndPoint<T>() {
    return this.http.get<T>(this.getPriceUrl).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }


}
