import {IFormData} from '../../models/form-data';
import {FormControl, FormGroup} from '@angular/forms';
import {FormFieldType} from '../../models/form-field-type.enum';

export function createFormGroup<T>(controls: IFormData<T>[]): FormGroup {
  const group = {};
  for (const control of controls) {
    if (control.type === FormFieldType.Group) {
      const subGroup = {};
      for (const subControl of control.controls) {
        subGroup[subControl.name] = new FormControl(
          control.value || '',
          control.validators || []
        );
      }
      group[control.name] = new FormGroup(subGroup);
    } else {
      group[control.name] = new FormControl(
        control.value || '',
        control.validators || []
      );
    }
  }
  return new FormGroup(group);
}
