import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints, MediaMatcher} from '@angular/cdk/layout';
import {StoreSummaryService} from '../store-summary/store-summary.service';
import {StoreSummary} from '../store-summary/store-summary';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-dash',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  mdq: MediaQueryList;
  mediaQueryListener: () => void;
  rowHeight?: string;

  miniCardData: StoreSummary[];
  cardLayout = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(
    map(({matches}) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: {cols: 1, rows: 1},
          chart: {cols: 1, rows: 2},
          table: {cols: 1, rows: 2},
        };
      }

      return {
        columns: 4,
        miniCard: {cols: 1, rows: 1},
        chart: {cols: 2, rows: 2}
      };
    })
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private summaryService: StoreSummaryService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public translator: TranslatorService
  ) {


  }


  ngOnInit() {


    this.summaryService.getStoreSummary().subscribe({
      next: summaryData => {
        this.miniCardData = summaryData;
      }
    });
  }
}
