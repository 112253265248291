import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {AccountService} from '../../services/account/account.service';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {IFormData} from '../../models/form-data';
import {createFormGroup} from '../../shared/form-control/form-control.utility';
import {FormFieldType} from '../../models/form-field-type.enum';

import {Platform} from '@ionic/angular';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  preserveWhitespaces: true
})
export class LoginComponent implements OnInit {

  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  loginForm: FormGroup;
  isSubmitted = false;
  isLoggingIn?: boolean;

  // Dynamic form controls
  controls: IFormData<any>[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    public alertService: AlertService,
    private oneSignal: OneSignal,
    private platform: Platform,
    public translator: TranslatorService
  ) {
  }

  ngOnInit(): void {
    // Initialize form controls
    this.controls = [
      {type: FormFieldType.Email, label: 'Email', name: 'emailAddress', validators: [Validators.required]},
      {type: FormFieldType.Password, label: 'Password', name: 'password', validators: [Validators.required]},
    ];
    this.loginForm = createFormGroup(this.controls);
  }

  get formControls() {
    return this.loginForm.controls;
  }


  login() {
    const user = this.loginForm.value;
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoggingIn = true;
    this.accountService.getLogin(user).subscribe(
      results => this.onSuccess(results),
      errors => this.handleError(errors),
      () => this.onComplete()
    );

  }

  loggedIn() {
    return this.authService.isLoggedIn();
  }

  onSuccess(user: any): void {
    this.isLoggingIn = false;
    sessionStorage.setItem('TOKEN', String(user.token));
    sessionStorage.setItem('TOKEN_EXPIRATION', String(user.expiration));
    sessionStorage.setItem('IS_SUBSCRIBER', String(user.isSubscriber));
    if (user.token != null) {
      this.router.navigateByUrl('/dashboard');
      this.alertService.success(this.translator.t('Login'), this.translator.t('You have successfully logged in'), this.options);
    }


    this.platform.ready().then(() => {
      const externalUserId = user.userId;
      this.oneSignal.setExternalUserId(externalUserId);

    });


  }

  handleError(errors): void {
    this.isLoggingIn = false;
    this.alertService.errors(errors);

  }


  onComplete(): void {

  }

}
