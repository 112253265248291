import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteEndpointService {
  public baseUrl = environment.api;

  get getSendUrl() { return this.baseUrl + 'invite/send'; }
  send<T>(emails: any): Observable<T> {

    return this.http.post<T>(this.getSendUrl, JSON.stringify(emails)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }
  protected handleError(message) {
    return throwError(message.error);
  }
  constructor(private http: HttpClient) { }
}
