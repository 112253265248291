import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AccountService} from 'src/app/services/account/account.service';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {IFormData} from '../../models/form-data';
import {createFormGroup} from '../../shared/form-control/form-control.utility';
import {FormFieldType} from '../../models/form-field-type.enum';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  hasForgotPassword?: boolean;

  // Dynamic form controls
  controls: IFormData<any>[] = [];

  constructor(
    public router: Router, private formBuilder: FormBuilder, private accountService: AccountService, private alertService: AlertService,
    public translator: TranslatorService
    ) {
  }

  ngOnInit(): void {
    // Initialize form controls
    this.controls = [
      {type: FormFieldType.Email, label: 'Email', name: 'emailAddress', validators: [Validators.required, Validators.email]}
    ];
    this.forgotPasswordForm = createFormGroup(this.controls);
  }

  get formControls() {
    return this.forgotPasswordForm.controls;
  }


  forgotPassword() {
    const user = this.forgotPasswordForm.value;
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.hasForgotPassword = true;
    this.accountService.forgotPassword(user.emailAddress).subscribe(
      data => this.onSuccess(data),
      error => this.handleError(error),
      () => this.onComplete());

  }


  onSuccess(data: any): void {
    this.hasForgotPassword = false;
    this.alertService.success(data.key, data.message);
  }

  handleError(errors): void {
    this.hasForgotPassword = false;
    this.alertService.errors(errors);
  }

  onComplete(): void {
  }

}
