import { Injectable } from '@angular/core';
import { InviteEndpointService } from './invite-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor(private inviteEndPoint: InviteEndpointService) { }

  send(emails: any) {
    return this.inviteEndPoint.send(emails);
  }

}
