import { Injectable } from '@angular/core';
import { IPrice } from 'src/app/models/price.model';
import { Profile } from 'src/app/models/profile.model';
import { ProfileEndpointService } from './profile-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private profileEndPoint: ProfileEndpointService) { }



  editProfile(user: any) {
    return this.profileEndPoint.editProfileEndPoint<Profile>(user);
  }
  getProfile() {
    return this.profileEndPoint.getProfileEndpoint<Profile>();
  }
  getAvatar() {
    return this.profileEndPoint.getAvatar<any>();
  }
}
