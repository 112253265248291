import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';

@Component({
  selector: 'app-sales-traffic-chart',
  templateUrl: './sales-traffic-chart.component.html',
  styleUrls: ['./sales-traffic-chart.component.scss']
})
export class SalesTrafficChartComponent implements OnInit {
  public pieChartLabels: Label[] = ['Search', 'Direct', 'Social', 'Email', 'Unknown'];
  public pieChartData: SingleDataSet[] = [[1, 2, 3, 4, 5]];


  constructor() {
  }

  ngOnInit() {

  }

}
