import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {IFormData} from '../../models/form-data';
import {FormFieldType} from '../../models/form-field-type.enum';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
})
export class FormControlComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() data: IFormData<any>;

  FieldType = FormFieldType;

  get isValid(): boolean {
    return this.form.controls[this.data.name].valid;
  }

  constructor(
    public translator: TranslatorService
  ) {
  }

  ngOnInit(): void {
  }

  isRequired() {
    return this.data.validators && this.data.validators.includes(Validators.required);
  }

  getTypeString(type: FormFieldType) {
    switch (type) {
      case FormFieldType.None:
        return '';
      case FormFieldType.Email:
        return 'email';
      case FormFieldType.Text:
        return 'text';
      case FormFieldType.Password:
        return 'password';
      case FormFieldType.Group:
        return '';
      case FormFieldType.MultiSelect:
        return '';
      case FormFieldType.Checkbox:
        return 'checkbox';
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.claimValue === o2.claimValue;
  }
}
