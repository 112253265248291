import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/operators';
import { Alert, AlertType } from 'src/app/models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';
  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // convenience methods
  success(title: string, message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Success, title, message }));
  }

  error(title: string, message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Error, title, message }));
  }


  subscription(title: string, message: any, status?: any, isSubscriptionActiveOrTrialling?: any, options?: any) {

    if (status === 'trialing') {

      this.alert(new Alert({ ...options, type: AlertType.Error, title, message }));
    }
  }


  errors(errorMessages, options?: any) {
    for (const e of errorMessages) {
      const title = e.key;
      const message = e.errorMessage;
      this.alert(new Alert({ ...options, type: AlertType.Error, title, message }));
    }
  }

  info(title: string, message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Info, title, message }));
  }

  warn(title: string, message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Warning, title, message }));
  }

  // main alert method
  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.subject.next(new Alert({ id }));
  }
}
