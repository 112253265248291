import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { RingsizeService } from 'src/app/services/ringsize/ringsize.service';

@Component({
  selector: 'app-letters-by-middle',
  templateUrl: './letters-by-middle.component.html',
  styleUrls: ['./letters-by-middle.component.scss'],
})
export class LettersByMiddleComponent implements OnInit {

  g?: any;
  h?: any;
  i?: any;
  j?: any;
  k?: any;
  l?: any;
  m?: any;
  n?: any;
  o?: any;
  p?: any;
  q?: any;
  r?: any;
  s?: any;
  t?: any;
  u?: any;
  v?: any;
  x?: any;
  y?: any;
  z?: any;
  z1?: any;
  z2?: any;
  z3?: any;
  z4?: any;
  z5?: any;
  z6?: any;
  na?: any;
  
  public middleFingerChartLabels: Label[] = [
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "U",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "Z1",
      "Z2",
      "Z3",
      "Z4",
      "Z5",
      "Z6",
      "Z7",
      "N/A"
  ];

  public middleFingerChartData: ChartDataSets[] = [
    { data: [65, 59, 90, 81, 65, 59, 90, 81,65, 59, 90, 81,65, 59, 90, 81,65, 59, 90, 81,65, 59, 90, 81,65, 59, 90, 81] }
  ];

  public middleFingerChartColors: Color[] = [
    {
      backgroundColor: 'rgba(0,0,255)',
    },
  ];
  
  public middleFingerChartType: ChartType = 'bar';

  constructor(private ringSizeService: RingsizeService) {
    this.ringSizeService.getCountOfRingSizeLettersForMiddleFinger().subscribe(
      results => this.onSuccess(results),
      error => this.onError(error)
    );
  }
  onError(error: any): void {
    console.log(error);
  }
  onSuccess(results: any): void {
    console.log(results);
    const { g, h, i, j, k, l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,z1,z2,z3,z4,z5,z6,na } = results;

 
    this.middleFingerChartData = [{
      data: [g, h, i, j, k, l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,z1,z2,z3,z4,z5,z6,na]
    }]
  }

  ngOnInit() {}

}
