
import { Component, NgModule } from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  getYear() {
    return new Date().getUTCFullYear();
  }
}

@NgModule({
  imports: [MatToolbarModule],
  exports: [FooterComponent],
  declarations: [FooterComponent],
})
export class FooterModule {

 }
