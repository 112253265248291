import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertService } from '../alert-service/alert-service.service';
import { PlansEndpointService } from './plans-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private plansEndPoint: PlansEndpointService, private alertService: AlertService) { }

  getPlansByMonth() {
    return this.plansEndPoint.getPlansByMonth();
  }
  getPlansByYear() {
    return this.plansEndPoint.getPlansByYear();
  }

}
