import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileEndpointService {

  public baseUrl = environment.api;

  constructor(private http: HttpClient) { }

  get getProfileUrl() { return this.baseUrl + 'profile/getProfile'; }
  get editProfileUrl() { return this.baseUrl + 'profile/edit'; }
  get getAvatarUrl() { return this.baseUrl + 'profile/getAvatar'; }

  getAvatar<T>() {
    return this.http.get<T>(this.getAvatarUrl).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  editProfileEndPoint<T>(user: any) {
    return this.http.post<T>(this.editProfileUrl, JSON.stringify(user)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }
  getProfileEndpoint<T>() {

    return this.http.get<T>(this.getProfileUrl).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  protected handleError(message) {
    console.log(message.error);
    return throwError(message.error);
  }



}
