import { Injectable } from '@angular/core';
import { AccountEndpoint } from '../account-endpoint/account-endpoint.service';
import { IUser } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  constructor(private accountEndpoint: AccountEndpoint) { }

  getLogin(user: any) {
    return this.accountEndpoint.getLoginUserEndpoint(user);
  }

  getConfirmAccount(emailAddress: string) {
    return this.accountEndpoint.getConfirmAccount(emailAddress);
  }



  forgotPassword(email) {
    return this.accountEndpoint.getForgotPasswordUserEndpoint<string>(email);
  }

  subscribeUser(user) {
    return this.accountEndpoint.getSubscribeUserEndpoint<IUser>(user);
  }

  login(user) {
    return this.accountEndpoint.getLoginUserEndpoint<IUser>(user);
  }

  resetPassword(password) {
    return this.accountEndpoint.resetPasswordEndpoint<IUser>(password);
  }


}
