import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Stripe} from 'src/app/models/stripe.model';
import {UserModel} from 'src/app/models/user-model.model';
import {EqualValidator} from '../validators/equal.validator';
import {StripeService} from 'src/app/services/stripe/stripe.service';
import {ProfileService} from 'src/app/services/profile/profile.service';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from 'src/app/services/auth/auth.service';
import {ConfirmDialogComponent} from '../../shared/dialog/confirm-dialog.component';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {IFormData} from '../../models/form-data';
import {createFormGroup} from '../../shared/form-control/form-control.utility';
import {FormFieldType} from '../../models/form-field-type.enum';
import {TranslatorService} from '../../services/translator/translator.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  avatar?: string;
  profile: UserModel;
  claims: any[];
  selectedClaims: any[];
  profileForm: FormGroup;
  stripeForm: FormGroup;
  isSubmitted = false;
  emailAddress: string;
  stripe?: Stripe;
  subscriber?: any;
  isSubscriptionActiveOrTrialling: any;
  isStripeProfileRetrieved?: boolean;

  // Dynamic form controls
  controls: IFormData<any>[] = [];

  constructor(
    activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private stripeProfileService: StripeService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private auth: AuthService,
    public translator: TranslatorService
  ) {
    const email: string = activatedRoute.snapshot.params.email;
    this.emailAddress = email;

    this.profileService.getProfile().subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error),
      () => this.onComplete()
    );

    if (!this.auth.getRoles().includes('admin')) {
      this.subscriber = true;
    }
  }


  onDataLoadFailed(errors: any): void {
  }

  onDataLoadSuccessful(results: any): void {
    this.profile = results.user;
    this.avatar = results.avatar;
    console.log(results);
    this.claims = results.allClaims;
    this.selectedClaims = results.claims;

    // Initialize form controls
    this.controls = [
      {
        type: FormFieldType.Email,
        label: 'Email',
        value: this.profile.email,
        name: 'emailAddress',
        validators: [Validators.required, Validators.email]
      },
      {type: FormFieldType.Text, label: 'First Name', value: this.profile.firstname, name: 'firstname'},
      {type: FormFieldType.Text, label: 'Surname', value: this.profile.surname, name: 'surname'},
      {
        type: FormFieldType.Group,
        name: 'password',
        controls: [
          {
            type: FormFieldType.Password,
            label: 'New Password',
            name: 'newPassword',
            validators: [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/)]
          },
          {
            type: FormFieldType.Password,
            label: 'Confirm Password',
            name: 'confirmPassword',
            validators: [Validators.required, EqualValidator('newPassword')]
          },
        ]
      }
    ];
    this.profileForm = createFormGroup(this.controls);
  }


  ngOnInit(): void {
    this.profileService.getProfile().subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error),
      () => this.onComplete()
    );

    if (this.subscriber != null) {
      this.isStripeProfileRetrieved = true;
      this.stripeProfileService.getStripeProfile().subscribe(
        data => this.onStripeProfileLoadSuccessful(data),
        errors => this.onStripeProfileLoadFailed(errors)
      );
    }
  }

  onStripeProfileLoadFailed(errors: any) {
    this.isStripeProfileRetrieved = false;
  }

  onStripeProfileLoadSuccessful(result: any) {
    this.isStripeProfileRetrieved = false;
    this.stripe = result;
  }

  get formControls() {
    return this.profileForm.controls;
  }

  get stripeFormControls() {
    return this.stripeForm.controls;
  }

  get password() {
    return this.profileForm.get('password');
  }

  get newPassword() {
    return this.password.get('newPassword');
  }

  get confirmPassword() {
    return this.password.get('confirmPassword');
  }

  editUserProfile() {
    const profile = this.profileForm.value;
    this.profileService.editProfile(profile).subscribe(
      data => this.onSuccess(data),
      error => this.handleError(error),
      () => this.onComplete());
  }

  onSuccess(data): void {
    this.alertService.success(data.key, data.message);
  }

  cancel() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {title: 'Cancel subscription', message: this.translator.t('Do you want to cancel your subscription?'), yes:  this.translator.t('Yes'), no:  this.translator.t('No')}
    }).afterClosed().subscribe(result => {
      if (result ===  this.translator.t('Yes')) {
        this.stripeProfileService.cancelStripeSubscription().subscribe(
          results => this.onSuccessfulSubscriptionCancel(results),
          error => this.onSuccessfulSubscriptionCancelError(error)
        );
      }
    });
  }

  restart() {
    this.stripeProfileService.restartStripeSubscription().subscribe(
      results => this.onSuccessfulSubscriptionRestart(results),
      error => this.onSuccessfulSubscriptionCancelRestart(error)
    );
  }

  onSuccessfulSubscriptionCancelRestart(errors: any) {
    this.alertService.errors(errors);
  }

  onSuccessfulSubscriptionRestart(data: any) {
  }

  onSuccessfulSubscriptionCancelError(errors: any) {
    this.alertService.errors(errors);
  }

  onSuccessfulSubscriptionCancel(data: any) {
    if (this.subscriber != null) {
      this.isStripeProfileRetrieved = true;
      this.stripeProfileService.getStripeProfile().subscribe(
        result => this.onStripeProfileLoadSuccessful(result),
        errors => this.onStripeProfileLoadFailed(errors)
      );
    }
    this.alertService.info(data.key, data.message);
  }

  handleError(errors): void {
    this.alertService.errors(errors);
  }

  onComplete(): void {
  }
}
