import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './auth-guards/can-activate/admin.guard';
import { AuthGuard } from './auth-guards/can-activate/auth.guard';
import { SubscriptionStatusGuard } from './auth-guards/can-activate/subscription-status.guard';
import { AboutComponent } from './components/about/about.component';
import { AddRoleComponent } from './components/admin/add-role/add-role.component';
import { AddUserComponent } from './components/admin/add-user/add-user.component';
import { EditRoleComponent } from './components/admin/edit-role/edit-role.component';
import { EditUserComponent } from './components/admin/edit-user/edit-user.component';
import { RoleListComponent } from './components/admin/role-list/role-list.component';
import { UsersListComponent } from './components/admin/users-list/users-list.component';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { InviteComponent } from './components/invite/invite.component';
import { StripeUpgradeComponent } from './components/stripe/stripe-upgrade/stripe-upgrade.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { PlansComponent } from './components/stripe/plans/plans.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Dashboard' } },
  { path: 'invite', component: InviteComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Invite' } },
  { path: 'login', component: LoginComponent },
  {
    path: 'profile', canActivate: [AuthGuard],
    data: { breadcrumb: 'Profile' }, children: [
      {
        path: '',
        pathMatch: 'full',
        component: ProfileComponent,
      },
      { path: 'plans', component: PlansComponent, data: { breadcrumb: 'Plans' } },
      { path: 'stripe-upgrade', component: StripeUpgradeComponent, data: { breadcrumb: 'Upgrade' } },
    ],
  },
  { path: 'about', component: AboutComponent },
  { path: 'confirm-account', component: ConfirmAccountComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'subscribe', component: SubscribeComponent },
  {
    path: 'roles', canActivate: [AuthGuard, AdminGuard],
    data: { breadcrumb: 'Roles' }, children: [
      {
        path: '',
        pathMatch: 'full',
        component: RoleListComponent
      },
      { path: 'add-role', component: AddRoleComponent, data: { breadcrumb: 'Add Role' } },
      { path: 'edit-role', component: EditUserComponent, data: { breadcrumb: 'Edit Role' } },
      { path: 'edit-role/:role', component: EditRoleComponent, data: { breadcrumb: 'Edit Role' } }
    ],
  },
  {
    path: 'users', canActivate: [AuthGuard, AdminGuard], data: { breadcrumb: 'Users' }, children: [
      {
        path: '',
        pathMatch: 'full',
        component: UsersListComponent

      },

      { path: 'add-user', component: AddUserComponent, data: { breadcrumb: 'Add User' } },
      { path: 'edit-user', component: EditUserComponent, data: { breadcrumb: 'Edit User' } },
      { path: 'edit-user/:email', component: EditUserComponent, data: { breadcrumb: 'Edit Users' } }
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
