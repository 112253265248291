import { AfterViewInit, Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { TableColumn } from './TableColumn';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterViewInit, OnInit {
  public displayedColumns: string[];
  public dataSource = new MatTableDataSource([]);

  api: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() tableColumnHeader: any;
  pageLength: number;
  @Input() paginationSizeOptions: number[] = [5, 10, 15];
  @Input() pageSize = this.paginationSizeOptions[1];
  @Input() isFilterable = false;
  @Input() filterPlaceHolder = 'filter';
  @Input() filterLabel = 'Filter';
  @Input() alignment = 'center';

  @Output() sortData: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();

  objectKeys = Object.keys;

  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  constructor(
    public translator: TranslatorService
  ) {
    this.api = environment.apiHost;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    const columnNames = this.tableColumnHeader.map((tableColumn: TableColumn) => tableColumn.key);
    this.displayedColumns = columnNames;
  }

  setTableDataSource(data: any) {
    this.dataSource = new MatTableDataSource<any>(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  sortTable(sortParameters: Sort) {
    this.sortData.emit(sortParameters);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.translator.t('Items per page');
  }
}

