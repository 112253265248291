import {Component, Input} from '@angular/core';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-mini-card',
  templateUrl: './mini-card.component.html',
  styleUrls: ['./mini-card.component.scss']
})
export class MiniCardComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() value: any;
  @Input() color: string;
  @Input() isIncrease: boolean;
  @Input() isCurrency: boolean;
  @Input() duration: string;
  @Input() percentValue: any;

  constructor(
    public translator: TranslatorService
  ) {
  }
}
