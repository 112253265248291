import {AfterViewInit, Component, EventEmitter, InjectionToken, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {UserService} from 'src/app/services/user/user.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../shared/dialog/confirm-dialog.component';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {TableColumn} from 'src/app/shared/table/TableColumn';
import {TranslatorService} from '../../../services/translator/translator.service';

export const DIALOG_DATA = new InjectionToken('DIALOG_DATA');

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class UsersListComponent implements AfterViewInit, OnInit {
  @Output() newMessageEvent = new EventEmitter<boolean>();
  /**
   *
   */
  userTableData = [];
  userTableColumns: TableColumn[] = [
    {
      name: 'Avatar',
      key: 'avatar',
      dataType: 'string',
      isButton: false,
      isOrderStatus: false,
      isSortable: true,
      isVisibleMobile: false,
      routerLink: null,
      icon: null,
      toolTipText: ''

    },
    {
      name: 'Firstname',
      key: 'firstname',
      dataType: 'string',
      isButton: false,
      isOrderStatus: false,
      isSortable: true,
      isVisibleMobile: true,
      routerLink: null,
      icon: null,
      toolTipText: ''

    },
    {
      name: 'Surname',
      key: 'surname',
      dataType: 'string',
      isButton: false,
      isOrderStatus: false,
      isSortable: true,
      isVisibleMobile: true,
      routerLink: null,
      icon: null,
      toolTipText: ''
    },
    {
      name: 'Email Address',
      key: 'email',
      dataType: 'string',
      isButton: false,
      isOrderStatus: false,
      isSortable: true,
      isVisibleMobile: false,
      routerLink: null,
      icon: null,
      toolTipText: ''

    },
    {
      name: 'User Name',
      key: 'userName',
      dataType: 'string',
      isButton: false,
      isOrderStatus: false,
      isSortable: true,
      isVisibleMobile: false,
      routerLink: null,
      icon: null,
      toolTipText: ''
    },
    {
      name: 'Edit',
      key: 'edit',
      dataType: '',
      isButton: false,
      isOrderStatus: false,
      isSortable: false,
      isVisibleMobile: true,
      routerLink: {path: '/users/edit-user', param: 'email', icon: 'edit'},
      icon: null,
      toolTipText: this.translator.t('Click here to edit user')
    },
    {
      name: 'Delete',
      key: 'delete',
      dataType: 'default',
      isButton: true,
      isOrderStatus: false,
      isSortable: false,
      isVisibleMobile: true,
      routerLink: null,
      icon: 'delete',
      toolTipText: this.translator.t('Click here to delete user')

    }
  ];

  constructor(
    public location: Location,
    public router: Router,
    private userService: UserService,
    public dialog: MatDialog,
    private alertService: AlertService,
    public translator: TranslatorService
  ) {

  }

  ngAfterViewInit() {

    this.userService.getUsers().subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error)
    );
  }

  ngOnInit() {

  }

  getPaginatorData() {


    this.userService.getUsers().subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error)
    );

  }


  deleteUser(user) {
    console.log(user);
    this.dialog.open(ConfirmDialogComponent, {
      data: {title: this.translator.t('Delete User'), message: this.translator.t('Do you want to delete user?'), yes:  this.translator.t('Yes'), no:  this.translator.t('No')}
    }).afterClosed().subscribe(result => {
      if (result ===  this.translator.t('Yes')) {
        this.userService.deleteUser(user).subscribe(
          data => this.onSuccessfulDelete(data, user),
          error => this.onSuccessfulDeleteErrors(error)
        );
      }
    });

  }

  onSuccessfulDelete(data, user: any): void {
    this.alertService.success(data.key, data.message);
    this.userTableData = this.userTableData.filter(item => item !== user);


  }

  onSuccessfulDeleteErrors(errors: any): void {
    this.alertService.errors(errors);
  }

  onDataLoadFailed(errors: any): void {
    this.alertService.errors(errors);
  }

  onDataLoadSuccessful(results: any): void {
    this.userTableData = results;
    console.log(this.userTableData);
  }


}
