import { Injectable } from '@angular/core';
import { IAddClaim } from 'src/app/models/add-claim.model';
import { IClaim } from 'src/app/models/claim.model';
import { IDeleteClaim } from 'src/app/models/delete-claim.model';
import { IRole } from 'src/app/models/role.model';
import { ClaimsEndpointService } from './claims-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor(private claimEndPoint: ClaimsEndpointService) { }

  getUserRoles() {
    return this.claimEndPoint.getUserRoles();
  }

  deleteRole(claimValue: any) {
    return this.claimEndPoint.deleteRoleEndpoint<IDeleteClaim>(claimValue);
  }
  editRole(role: any) {
    return this.claimEndPoint.editRoleEndPoint<IClaim>(role);
  }
  addRole(roleName: string) {
    return this.claimEndPoint.addRoleEndpoint<IAddClaim>(roleName);
  }
  getRole(claimValue: string) {
    return this.claimEndPoint.getRoleEndpoint<IClaim>(claimValue);
  }
  getRoles() {
    return this.claimEndPoint.getRolesEndpoint<IRole[]>();
  }
  getClaims() {
    return this.claimEndPoint.getClaimsEndPoint();
  }
}
