import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-page-settings-toolbar',
  templateUrl: './page-settings-toolbar.component.html',
  styleUrls: ['./page-settings-toolbar.component.scss']
})
export class PageSettingsToolbarComponent implements OnInit {
  cols: number;
  rows: number;
  rowHeight: number;

  @Output() expandedPageCols = new EventEmitter<number>();
  @Output() shrunkPageCols = new EventEmitter<number>();
  @Output() expandedPageRows = new EventEmitter<number>();
  @Output() shrunkPageRows = new EventEmitter<number>();
  @Output() myClick = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  expandPageCols() {
    this.expandedPageCols.emit(1);
  }

  shrinkPageCols() {

    this.shrunkPageCols.emit(1);

  }

  expandPageRows() {

    this.expandedPageRows.emit(50);
  }

  shrinkPageRows() {
    this.shrunkPageRows.emit(50);
  }

  save(){
    this.myClick.emit();
  }


}
