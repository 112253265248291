import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../services/account/account.service';
import { EqualValidator } from '../validators/equal.validator';
import { ResetPassword } from '../../models/reset-password.model';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { FormFieldType } from 'src/app/models/form-field-type.enum';
import { IFormData } from 'src/app/models/form-data';
import { createFormGroup } from 'src/app/shared/form-control/form-control.utility';
import {TranslatorService} from '../../services/translator/translator.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  email?: string;
  resetPasswordForm: FormGroup;
  isResettingPassword?: boolean;
    // Dynamic form controls
    controls: IFormData<any>[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public translator: TranslatorService
    ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.email = params.email;
    });

    this.buildForm();
  }

  private getPassword(): ResetPassword {
    const formModel = this.resetPasswordForm.value;

    return {
      email: this.email,
      newPassword: formModel.password.newPassword,
      confirmPassword: formModel.password.confirmPassword
    };
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }

  get newPassword() {
    return this.password.get('newPassword');
  }

  get confirmPassword() {
    return this.password.get('confirmPassword');
  }


  private buildForm() {
       // Initialize form controls
       this.controls = [
        {
          type: FormFieldType.Group,
          name: 'password',
          controls: [
            {
              type: FormFieldType.Password,
              label: 'New Password',
              name: 'newPassword',
              validators: [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/)]
            },
            {
              type: FormFieldType.Password,
              label: 'Confirm Password',
              name: 'confirmPassword',
              validators: [Validators.required, EqualValidator('newPassword')]
            },
          ]
        }
      ];
       this.resetPasswordForm = createFormGroup(this.controls);

  }

  resetPassword() {
    if (!this.resetPasswordForm.valid) {

      return;
    }

    const password = this.getPassword();


    this.isResettingPassword = true;

    this.accountService.resetPassword(password).subscribe(
      data => this.onSuccess(data),
      error => this.handleError(error),
      () => this.onComplete());

  }
  onSuccess(data: any): void {
    this.isResettingPassword = false;

    this.router.navigateByUrl('/login');
    this.alertService.success(data.key, data.message);
  }

  handleError(errors): void {
    this.isResettingPassword = false;
    this.alertService.errors(errors);
  }

  onComplete(): void {

  }
}
