import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { RingsizeService } from 'src/app/services/ringsize/ringsize.service';

@Component({
  selector: 'app-fingers',
  templateUrl: './fingers.component.html',
  styleUrls: ['./fingers.component.scss'],
})
export class FingersComponent implements OnInit {

  little?: any;
  index?: any;
  middle?: any;
  ring?: any;

  public fingerChartLabels: Label[] = [
    "Index",
    "Middle",
    "Ring",
    "Little"
  ];

  public fingerChartData: ChartDataSets[] = [
    { data: [65, 59, 90, 81] }
  ];

  
  public fingerChartColors: Color[] = [
    {
      backgroundColor: 'rgba(128,0,0)',
    },
  ];

  public fingerChartType: ChartType = 'bar';

  constructor(private ringSizeService: RingsizeService) {
    this.ringSizeService.getRingSizesCountsByFinger().subscribe(
      results => this.onSuccess(results),
      error => this.onError(error)
    );
  }
  onError(error: any): void {
    console.log(error);
  }
  onSuccess(results: any): void {
    console.log(results);
    const { index, middle, little, ring } = results;

    console.log(little);

    this.fingerChartData = [{
      data: [index, middle, little, ring]
    }]
  }

  ngOnInit() { }
}
