import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { UserModel } from 'src/app/models/user-model.model';

@Injectable({
  providedIn: 'root'
})

export class AccountEndpoint {

  public baseUrl = environment.api;

  get subscribeUrl() { return this.baseUrl + 'account/subscribe'; }
  get loginUrl() { return this.baseUrl + 'account/login'; }
  get forgotPasswordUrl() { return this.baseUrl + 'account/forgot'; }
  get resetPasswordUrl() { return this.baseUrl + 'account/reset'; }
  get getConfirmAccountUrl() { return this.baseUrl + 'account/confirmaccount'; }
  get getLoginUrl() { return this.baseUrl + 'account/login'; }

  constructor(private http: HttpClient, public auth: AuthService) {
  }

  getConfirmAccount(emailAddress: string) {
    const endpointUrl = this.getConfirmAccountUrl + '?email=' + emailAddress;

    return this.http.get(endpointUrl).pipe(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  getSubscribeUserEndpoint<T>(user: any): Observable<T> {

    return this.http.post<T>(this.subscribeUrl, JSON.stringify(user)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  protected handleError(message) {
    return throwError(message.error);
  }

  getLoginUserEndpoint<T>(user: any): Observable<T> {

    return this.http.post<T>(this.loginUrl, JSON.stringify(user)).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getForgotPasswordUserEndpoint<T>(email) {

    const endpointUrl = this.forgotPasswordUrl + '?email=' + email;

    return this.http.get<T>(endpointUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  resetPasswordEndpoint<T>(password) {
    return this.http.post<T>(this.resetPasswordUrl, JSON.stringify(password)).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

}
