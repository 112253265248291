import { Injectable } from '@angular/core';
import { IPrice } from 'src/app/models/price.model';
import { StripeUpgrade } from 'src/app/models/stripe-upgrade.model';
import { Stripe } from 'src/app/models/stripe.model';
import { StripeEndpointService } from './stripe-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private stripeEndPoint: StripeEndpointService) { }

  getSubscriptionStatus() {
    return this.stripeEndPoint.getSubscriptionStatus();
  }

  getPrice() {
    return this.stripeEndPoint.getPriceEndPoint<IPrice>();
  }

  restartStripeSubscription() {
    return this.stripeEndPoint.restartStripeSubscription();
  }
  cancelStripeSubscription() {
    return this.stripeEndPoint.cancelStripeSubscription();
  }
  stripeUpgrade(cardDetails: any) {
    return this.stripeEndPoint.upgradeStripeSubscriptionEndpoint<StripeUpgrade>(cardDetails);
  }
  getStripeProfile() {
    return this.stripeEndPoint.getStripeProfileEndpoint<Stripe>();
  }

}
