import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IClaim} from 'src/app/models/claim.model';
import {IRole} from 'src/app/models/role.model';
import {AlertService} from 'src/app/services/alert-service/alert-service.service';
import {ClaimsService} from 'src/app/services/claims/claims.service';
import {IFormData} from '../../../models/form-data';
import {createFormGroup} from '../../../shared/form-control/form-control.utility';
import {FormFieldType} from '../../../models/form-field-type.enum';
import {TranslatorService} from '../../../services/translator/translator.service';


@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {
  editRoleForm: FormGroup;
  claimValue?: string;
  role?: IRole;
  claim?: IClaim;
  loading = false;

  // Dynamic form controls
  controls: IFormData<any>[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private claimService: ClaimsService,
    private alertService: AlertService,
    public translator: TranslatorService
    ) {
    const claimValue: string = activatedRoute.snapshot.params.role;
    this.claimValue = claimValue;
  }

  onDataLoadFailed(errors: any): void {
    this.loading = false;
  }

  onDataLoadSuccessful(results: any): void {
    this.role = results;
    this.claim = results;
    this.claim.oldClaimValue = this.role.claimValue;
    this.loading = false;
    this.buildForm();
  }

  ngOnInit(): void {
    this.loading = true;
    this.claimService.getRole(this.claimValue).subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error)
    );
  }

  get formControls() {
    return this.editRoleForm.controls;
  }

  editRole() {
    this.claim.newClaimValue = this.editRoleForm.value.claimValue;
    this.claim.oldClaimValue = this.role.claimValue;
    this.claimService.editRole(this.claim).subscribe(
      data => this.onSuccess(data),
      error => this.handleErrors(error),
      () => this.onComplete());
  }

  onSuccess(data: any): void {
    this.alertService.success(data.key, data.message);
  }


  handleErrors(errors): void {
    this.alertService.errors(errors);
  }

  private buildForm() {
    // Initialize form controls
    this.controls = [
      {type: FormFieldType.Text, label: 'Role', name: 'claimValue', value: this.role.claimValue, validators: [Validators.required]},
    ];
    this.editRoleForm = createFormGroup(this.controls);
  }

  onComplete(): void {
  }

}
