import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label, SingleDataSet} from 'ng2-charts';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {

  @Input() chartData: SingleDataSet[];
  @Input() chartType: ChartType;
  @Input() chartColors: Color[];
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };
  chartLegend = false;
  chartPlugins = [];

  translatedChartLabels: Label[] = [];

  get chartLabels(): Label[] {
    return this.translatedChartLabels;
  }

  @Input() set chartLabels(labels: Label[]) {
    this.translatedChartLabels = labels.map(l => this.translator.t((l + '').trim()));
  }


  translatedChartDataSet: ChartDataSets[] = [];

  get chartDataSet() {
    return this.translatedChartDataSet;
  }

  @Input() set chartDataSet(dataset: ChartDataSets[]) {
    for (const data of dataset) {
      data.label = this.translator.t(data.label);
    }
    this.translatedChartDataSet = dataset;
  }

  constructor(
    public translator: TranslatorService
  ) {
  }

  ngOnInit() {
    this.chartLegend = this.chartType === 'pie';
  }

}
