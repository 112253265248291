import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { IUser } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isUserLoggedIn: boolean;


  constructor(private http: HttpClient, public router: Router) {

  }

  public isLoggedIn(): boolean {
    if (sessionStorage.getItem('TOKEN') != null) {
      return true;
    }

    return false;
  }

  getTokenExpiration() {
    return sessionStorage.getItem('TOKEN_EXPIRATION');
  }

  public getToken() {
    return sessionStorage.getItem('TOKEN');
  }

  public logout() {
    sessionStorage.removeItem('STATUS');
    sessionStorage.removeItem('TOKEN');

    this.isLoggedIn();
  }

  public removeToken() {
    return sessionStorage.removeItem('TOKEN');
  }

  public removeRoles() {
    return sessionStorage.removeItem('ROLES');
  }

  public getRoles(){
    return sessionStorage.getItem('ROLES');
  }

  public getSubscriptionStatus(){
    return sessionStorage.getItem('STATUS');
  }

  public checkIfUserIsSubscriber(){
    return sessionStorage.getItem('IS_SUBSCRIBER');
  }

  public removeSubscriptionStatus(){
    return sessionStorage.removeItem('STATUS');
  }
}
