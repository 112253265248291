import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IFormData } from 'src/app/models/form-data';
import { FormFieldType } from 'src/app/models/form-field-type.enum';
import { StripeUpgrade } from 'src/app/models/stripe-upgrade.model';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { createFormGroup } from 'src/app/shared/form-control/form-control.utility';
@Component({
  selector: 'app-stripe-upgrade',
  templateUrl: './stripe-upgrade.component.html',
  styleUrls: ['./stripe-upgrade.component.scss'],
})
export class StripeUpgradeComponent implements OnInit {
  stripeUpgradeForm: FormGroup;
  cardDetails: StripeUpgrade;
  price?: any;
  isSubscribing?: boolean;
  amount: any;
  product: any;
  // Dynamic form controls
  controls: IFormData<any>[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.amount = params.amount;
      this.product = params.product;
    });

    this.buildForm();
  }

  get formControls() {
    return this.stripeUpgradeForm.controls;
  }

  ngOnInit(): void {
    this.stripeService.getPrice().subscribe(
      (results) => this.onPriceLoadSuccessful(results),
      (error) => this.onPriceLoadFailed(error),
      () => this.onPriceComplete()
    );
  }

  onPriceComplete() {}
  onPriceLoadFailed(error: any) {}
  onPriceLoadSuccessful(results: any) {
    this.price = results;
  }

  stripeUpgrade() {
    const cardDetails = this.stripeUpgradeForm.value;
    this.isSubscribing = true;
    this.stripeService.stripeUpgrade(cardDetails).subscribe(
      (data) => this.onSuccess(data),
      (error) => this.handleError(error),
      () => this.onComplete()
    );
  }
  onComplete(): void {
    this.isSubscribing = false;
  }
  handleError(errors: any): void {
    this.isSubscribing = false;
    this.alertService.errors(errors);
  }
  onSuccess(data): void {
    this.router.navigateByUrl('/profile');
    this.alertService.success(data.key, data.message);
  }

  private buildForm() {
    this.controls = [
      {
        type: FormFieldType.Text,
        label: 'Card Number',
        name: 'cardNumber',
        validators: [
          Validators.required,
          Validators.pattern(
            /4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11}/g
          ),
          Validators.minLength(16),
          Validators.maxLength(16),
        ],
      },
      {
        type: FormFieldType.Text,
        label: 'Expiration Month',
        name: 'expMonth',
        validators: [Validators.required, Validators.minLength(2)],
      },
      {
        type: FormFieldType.Text,
        label: 'Expiration Year',
        name: 'expYear',
        validators: [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(4),
        ],
      },
      {
        type: FormFieldType.Text,
        label: 'CVC',
        name: 'cvc',
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
        ],
      },
    ];
    this.stripeUpgradeForm = createFormGroup(this.controls);
  }
}
