import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from 'src/app/models/user-model.model';
import { EqualValidator } from '../../validators/equal.validator';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { UserService } from 'src/app/services/user/user.service';
import { IFormData } from '../../../models/form-data';
import { createFormGroup } from '../../../shared/form-control/form-control.utility';
import { FormFieldType } from '../../../models/form-field-type.enum';
import {TranslatorService} from '../../../services/translator/translator.service';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  user: UserModel;
  claims: any[];
  selectedClaims: any[];
  addUserForm: FormGroup;
  isSubmitted = false;
  emailAddress: string;

  // Dynamic form controls
  controls: IFormData<{ id?: number; claimValue?: string; }>[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private claimService: ClaimsService,
    private router: Router,
    private alertService: AlertService,
    public translator: TranslatorService
  ) {
    this.emailAddress = activatedRoute.snapshot.params.email;
  }

  onDataLoadFailed(errors: any): void {
  }

  onDataLoadSuccessful(results: any): void {
    this.claims = results;
    this.buildForm();
  }

  ngOnInit(): void {


    this.claimService.getClaims().subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error)
    );


  }

  get formControls() {
    return this.addUserForm.controls;
  }

  get password() {
    return this.addUserForm.get('password');
  }

  get newPassword() {
    return this.password.get('newPassword');
  }

  get confirmPassword() {
    return this.password.get('confirmPassword');
  }


  addUser() {
    const user = this.addUserForm.value;

    if (user.claims.length > 0) {
      user.claims.filter(x => x.claimValue);
      user.isEnabled = this.user?.isEnabled || false;


      this.userService.addUser(user).subscribe(
        data => this.onSuccess(data),
        error => this.handleErrors(error),
        () => this.onComplete());
    } else {

    }

  }

  onSuccess(data): void {
    this.alertService.success(data.key, data.message);
  }


  handleErrors(errors): void {
    this.alertService.errors(errors);
  }

  private buildForm() {
    // Initialize form controls
    this.controls = [
      { type: FormFieldType.Email, label: 'Email', name: 'emailAddress', validators: [Validators.required, Validators.email] },
      { type: FormFieldType.Text, label: 'First Name', name: 'firstname', validators: [Validators.required] },
      { type: FormFieldType.Text, label: 'Surname', name: 'surname', validators: [Validators.required] },
      {
        type: FormFieldType.Group,
        name: 'password',
        controls: [
          {
            type: FormFieldType.Password,
            label: 'New Password',
            name: 'newPassword',
            validators: [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/)]
          },
          {
            type: FormFieldType.Password,
            label: 'Confirm Password',
            name: 'confirmPassword',
            validators: [Validators.required, EqualValidator('newPassword')]
          },
        ]
      },
      {
        type: FormFieldType.MultiSelect,
        label: 'Roles',
        name: 'claims',
        displayName: 'claimValue',
        options: this.claims,
        validators: [Validators.required]
      },
      { type: FormFieldType.Checkbox, label: 'Is Enabled', value: (this.user?.isEnabled) || false, name: 'isEnabled' },
    ];
    this.addUserForm = createFormGroup(this.controls);
  }

  onComplete(): void {
  }

}
