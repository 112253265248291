import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslatorService} from '../services/translator/translator.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    public auth: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.auth.getToken() != null) {
      const loginDate = new Date(this.auth.getTokenExpiration());

      const today = new Date();

      if (new Date(loginDate) < today) {
        this.auth.removeToken();
        this.router.navigateByUrl('/login');
      }

    }

    if (this.auth.getToken() === '' || this.auth.getToken() == null) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json, text/plain, */*'
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json, text/plain, */*',
          Authorization: 'Bearer ' + this.auth.getToken()
        }
      });
    }

    if (request instanceof HttpRequest) {
      if (localStorage.getItem(TranslatorService.key)) {
        request = request.clone({
          url: request.url.includes('?')
            ? `${request.url}&lang=${localStorage.getItem(TranslatorService.key)}`
            : `${request.url}?lang=${localStorage.getItem(TranslatorService.key)}`
        });
      }
    }

    return next.handle(request);
  }

}
