import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

type Translations = { [key: string]: { [key: string]: string } };

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  private get locale() {
    return this.storage.getItem(TranslatorService.key) || this.defaultLocale;
  }

  private set locale(value) {
    if (typeof value !== 'string') {
      return;
    }
    this.storage.setItem(TranslatorService.key, value);
  }

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.loadTranslations().then(r => {
    });
  }

  public static readonly key = 'locale';

  private ready = false;
  private readonly defaultLocale = 'en';
  private readonly localesFilePath = '/assets/locales.json';
  private readonly storage = localStorage;

  private readonly locales = [
    'en', 'es'
  ];

  private translations: Translations = {};
  private strings: { [key: string]: number } = {};

  public getLocales(): string[] {
    return this.locales.map(l => l.toUpperCase());
  }

  public getActiveLocale(): string {
    let locale = this.locale === null ? this.defaultLocale.toUpperCase() :  this.locale.toUpperCase();

    return locale;
  }

  private async loadTranslations() {
    try {
      this.ready = false;
      const res: any = await this.http.get(this.localesFilePath).toPromise();
      if (res) {
        this.translations = res;
      }
      this.ready = true;
    } catch (e) {
      console.error(e);
      this.ready = true;
    }
  }

  public setLocale(locale: string): void {
    if (typeof locale !== 'string') {
      return;
    }
    this.locale = locale.toLowerCase();

    // reload the component
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  public getTranslations() {
    return this.translations;
  }

  /** Translate function */
  public t(text: string): string {
    // dev purpose only
    // this.strings[text] = 1;
    // if (!localStorage.getItem('$strings')) {
    //   localStorage.setItem('$strings', JSON.stringify({}));
    // }
    // const data: { [key: string]: number } = JSON.parse(localStorage.getItem('$strings'));
    // // tslint:disable-next-line:forin
    // data[text] = 1;
    // localStorage.setItem('$strings', JSON.stringify(data));
    // @ts-ignore
    // window.$strings = this.strings;
    if (typeof text !== 'string') {
      return text;
    }
    if (!this.translations[text]) {
      return text;
    }
    const translation = this.translations[text];
    return translation && translation[this.locale]
      ? translation[this.locale] : text;
  }
}
