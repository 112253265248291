import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RingsizeEndpoint {
 
  public baseUrl = environment.api;

  get getRingSizesUrl() { return this.baseUrl + 'ringsize/getringsizes'; }
  get getCountOfRingSizeByFingerUrl() { return this.baseUrl + 'ringsize/getcountofringsizebyfinger'; }
  get GetCountOfRingSizeByLetterForIndexFingerUrl() { return this.baseUrl + 'ringsize/getcountofringsizebyletterforindexfinger'; }
  
  get GetCountOfRingSizeByLetterForLittleFingerUrl() { return this.baseUrl + 'ringsize/getcountofringsizebyletterforlittlefinger'; }
  
  get GetCountOfRingSizeByLetterForMiddleFingerUrl() { return this.baseUrl + 'ringsize/getcountofringsizebyletterformiddlefinger'; }
  
  get GetCountOfRingSizeByLetterForRingFingerUrl() { return this.baseUrl + 'ringsize/getcountofringsizebyletterforRingfinger'; }

  constructor(private http: HttpClient, public auth: AuthService) { }

  getRingSizesEndpoint<T>(): Observable<T> {

    return this.http.get<T>(this.getRingSizesUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getCountOfRingSizeByFingerEndPoint<T>(): Observable<T> {

    return this.http.get<T>(this.getCountOfRingSizeByFingerUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }


  getCountOfRingSizeLettersForIndexFingerEndPoint<T>(): Observable<T> {
    return this.http.get<T>(this.GetCountOfRingSizeByLetterForIndexFingerUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getCountOfRingSizeLettersForLittleFingerEndPoint<T>(): Observable<T> {
    return this.http.get<T>(this.GetCountOfRingSizeByLetterForLittleFingerUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getCountOfRingSizeLettersForMiddleFingerEndPoint<T>(): Observable<T> {
    return this.http.get<T>(this.GetCountOfRingSizeByLetterForMiddleFingerUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getCountOfRingSizeLettersForRingFingerEndPoint<T>(): Observable<T> {
    return this.http.get<T>(this.GetCountOfRingSizeByLetterForRingFingerUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }
  protected handleError(message) {
    return throwError(message.error);
  }
}
