import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountEndpoint } from '../../services/account-endpoint/account-endpoint.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { IFormData } from '../../models/form-data';
import { createFormGroup } from '../../shared/form-control/form-control.utility';
import { FormFieldType } from '../../models/form-field-type.enum';
import {TranslatorService} from '../../services/translator/translator.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  subscribeForm: FormGroup;
  isSubmitted = false;
  subscribed?: boolean;
  isSubscribing?: boolean;
  color?: string;

  // Dynamic form controls
  controls: IFormData<any>[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountEndpoint,
    public router: Router,
    private alertService: AlertService,
    public translator: TranslatorService
    ) {
    this.color = '#FFFFFF';
  }

  ngOnInit(): void {
    // Initialize form controls
    this.controls = [
      { type: FormFieldType.Text, label: 'First name', name: 'firstName', validators: [Validators.required] },
      { type: FormFieldType.Text, label: 'Surname', name: 'surname', validators: [Validators.required] },
      { type: FormFieldType.Email, label: 'Email', name: 'emailAddress', validators: [Validators.required, Validators.email] },
      { type: FormFieldType.Password, label: 'Password', name: 'password', validators: [Validators.required] },
    ];
    this.subscribeForm = createFormGroup(this.controls);
  }

  get formControls() {
    return this.subscribeForm.controls;
  }

  subscribe() {
    const user = this.subscribeForm.value;
    this.isSubmitted = true;
    if (this.subscribeForm.invalid) {
      return;
    }

    this.isSubscribing = true;

    this.accountService.getSubscribeUserEndpoint(user).subscribe(
      data => this.onSuccess(data),
      error => this.handleError(error),
      () => this.onComplete());

  }

  onSuccess(data): void {
    this.router.navigateByUrl('/login');
    this.alertService.success(data.key, data.message);

  }

  handleError(errors): void {
    this.isSubscribing = false;
    this.alertService.errors(errors);
  }

  onComplete(): void {
    this.isSubscribing = false;
  }

}


