import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EditUser } from 'src/app/models/edit-user.model';
import { UserModel } from 'src/app/models/user-model.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserEndpointService {
  public baseUrl = environment.api;

  constructor(private http: HttpClient) { }

  get getUsersUrl() { return this.baseUrl + 'user/allusers'; }
  get getUserUrl() { return this.baseUrl + 'user/getUser'; }
  get editUserUrl() { return this.baseUrl + 'user/edit'; }
  get deleteUserUrl() { return this.baseUrl + 'user/delete'; }
  get addUserUrl() { return this.baseUrl + 'user/create'; }


  getUserEndpoint<T>(email: any): Observable<T> {
    const endpointUrl = email ? `${this.getUserUrl}?email=${email}` : this.getUserUrl;
    return this.http.get<T>(endpointUrl).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  deleteUserEndpoint<T>(user: any): Observable<T> {

    return this.http.post<T>(this.deleteUserUrl, JSON.stringify(user)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }


  getUsersEndpoint<T>(): Observable<T> {

    return this.http.get<T>(this.getUsersUrl).pipe<T>(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  addUserEndPoint<T>(user: UserModel) {
    return this.http.post<T>(this.addUserUrl, JSON.stringify(user)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  editUserEndPoint<T>(user: EditUser) {
    return this.http.post<T>(this.editUserUrl, JSON.stringify(user)).pipe<T>(
      catchError(message => {
        return this.handleError(message);
      }));
  }

  protected handleError(message) {
    return throwError(message.error);
  }
}
